<template>
  <div
    v-if="strapiData"
    class="page--customer-service"
  >
    <AppBreadcrumbs class="container" />

    <div class="customer-service__container container">
      <div
        v-if="banner"
        class="customer-service__banner"
      >
        <picture
          v-if="banner?.File?.Media?.url && banner?.File?.Media?.mime?.includes('image')"
          class="customer-service__picture"
        >
          <source
            :srcset="widescreenImage"
            media="(min-width: 1280px)"
          >
          <source
            :srcset="desktopImage"
            media="(min-width: 1024px)"
          >
          <source
            :srcset="tabletImage"
            media="(min-width: 768px)"
          >
          <source
            :srcset="phabletImage"
            media="(min-width: 640px)"
          >
          <source
            :srcset="mobileImage"
          >
          <img
            class="customer-service__image"
            :src="widescreenImage"
            :alt="banner?.File?.Media?.alternativeText || banner?.Name || ''"
            :width="banner?.File?.Media?.width"
            :height="banner?.File?.Media?.height"
            loading="eager"
            fetchpriority="high"
            decoding="sync"
          >
        </picture>

        <div class="customer-service__content">
          <h1
            v-if="banner?.Title"
            class="customer-service__heading heading heading--1"
          >
            {{ banner.Title }}
          </h1>

          <p
            v-if="banner?.Description"
            class="customer-service__description"
          >
            {{ banner.Description }}
          </p>
        </div>
      </div>

      <div class="customer-service__slices">
        <StrapiSlices
          v-if="strapiData?.customerServicePage?.Slices?.length"
          :slices="strapiData.customerServicePage.Slices"
        />
      </div>

      <div
        v-if="customerService"
        class="customer-service__contact"
      >
        <div
          v-if="customerService.Title"
          class="heading heading--6"
        >
          {{ customerService.Title }}
        </div>

        <p v-if="customerService.Description">
          {{ customerService.Description }}
        </p>

        <menu
          v-if="customerService?.Icons?.length"
          class="customer-service__menu"
        >
          <li
            v-for="group in customerService.Icons"
            :key="group"
          >
            <AppLink
              :to="group.Link"
              class="customer-service__link"
            >
              <component
                :is="loadIcon(group.Icon)"
                v-if="group.Icon"
                filled
                class="icon"
                :class="`icon--${useDash(group.Icon)}`"
              />
              <span>{{ group.Name }}</span>
            </AppLink>
          </li>
        </menu>

        <!-- <pre>customerService: {{ customerService }}</pre> -->
      </div>
    </div>

    <slot />
  </div>
  <DevOnly v-else-if="strapiError">
    <pre>strapiError: {{ strapiError }}</pre>

    <slot />
  </DevOnly>
</template>

<script lang="ts" setup>
import customerServicePage from '~layers/app/graphql/customerServicePage.gql'

const meta = inject('meta', ref())
const { strapiData, strapiError, getStrapiData } = useStrapiCms(meta?.value?.strapi_id, undefined, customerServicePage, undefined)
const { t } = useI18n({ useScope: 'global' })

await getStrapiData()

const banner = computed(() => strapiData.value?.customerServicePage?.Banner)
const customerService = computed(() => strapiData.value?.customerService?.[0])

const pageTitle = computed(
  () => strapiData.value?.customerServicePage?.PageTitle || '',
)

const metaTitle = computed(
  () => strapiData.value?.customerServicePage?.MetaTitle || pageTitle.value,
)

const metaDescription = computed(
  () => strapiData.value?.customerServicePage?.MetaDescription || pageTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs({
  label: metaTitle.value,
  parents: meta.value.parents,
})

const { pageView } = useDataLayer()
pageView('customer-service', metaTitle.value)

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: strapiData.value?.customerServicePage?.MetaData?.MetaRobots?.replace('_', ', ') },
  ],
  script: [
    { type: 'application/ld+json', hid: 'RichSnippets', innerHTML: strapiData.value?.MetaData?.RichSnippets || '' },
  ],
})

const route = useRoute()
const { canonical } = useSeo()
canonical(computed(() => route.path))

const { loadIcon } = useStrapiIcons()

const widescreenImage = computed(() => {
  const maxWidth = 1324
  return getProductImage(banner?.value?.File?.Media?.url?.replaceAll(' ', '%20'), 'f=rs:fit:' + maxWidth)
})

const desktopImage = computed(() => {
  const maxWidth = 1280
  return getProductImage(banner?.value?.File?.Media?.url?.replaceAll(' ', '%20'), 'f=rs:fit:' + maxWidth)
})

const tabletImage = computed(() => {
  const maxWidth = 1024
  return getProductImage(banner?.value?.File?.Media?.url?.replaceAll(' ', '%20'), 'f=rs:fit:' + maxWidth)
})

const phabletImage = computed(() => {
  const maxWidth = 768
  const url = (banner?.value?.File?.MediaMobile?.url || banner?.value?.File?.Media?.url)?.replaceAll(' ', '%20')
  return getProductImage(url, 'f=rs:fit:' + maxWidth)
})

const mobileImage = computed(() => {
  const maxWidth = 640
  const url = (banner?.value?.File?.MediaMobile?.url || banner?.value?.File?.Media?.url)?.replaceAll(' ', '%20')
  return getProductImage(url, 'f=rs:fit:' + maxWidth)
})
</script>

<style src="~layers/app/pages/~customer-service.css" />
